<template>
  <validation-provider
    v-show="items.length > 1"
    v-slot="{ errors }"
    :name="$t('Inventory')"
    :rules="required ? 'required' : ''"
  >
    <v-autocomplete
      v-model="inventoryId"
      :label="`* ${$t('Inventory')}`"
      :items="items"
      :item-text="item => item.name"
      :item-value="item => item.id"
      outlined
      dense
      :hide-details="hideDetails"
      :class="classes"
      :disabled="disabled"
      :clearable="clearable"
      :color="$_input_color()"
      :error-messages="errors"
    />
  </validation-provider>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: { type: [Number, String], default: null },
    classes: { type: String, default: null },
    hideDetails: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
  },

  computed: {
    ...mapGetters([
      'authUserData',
    ]),

    inventoryId: {
      get() {
        let inventory = this.value ? this.value : (this.items.length == 1 ? this.items[0].id : null)

        if (!this.value) {
          this.$emit('input', inventory)
        }

        return inventory
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>