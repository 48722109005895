import Vue from 'vue'
import { mapGetters } from 'vuex'
import store from '../store/index.js'
import router from '../router'
import vuetify from '../plugins/vuetify'
import i18n from '../plugins/i18n'
import Confirmation from '../components/Global/Confirmation.vue'

export default {
  methods: {
    $_index_column() {
      return { text: this.$t('#'), value: 'index', sortable: false }
    },

    $_small_input() {
      return this.$vuetify.breakpoint.name == 'xs' ? '' : 'small-input'
    },

    $_section_title_for_ls() {
      return this.sectionTitle.title ? this.sectionTitle.title.replaceAll(' ', '_').toLowerCase() + '_lav' : ''
    },

    $_input_color() {
      return ''
      // return '#409eff'
    },

    $_base_path() {
      return 'http://localhost:8000/'
    },

    $_image_path() {
      return 'http://localhost:8000/'
    },

    $_notify(message, type = 'success', timeout = 3000) {
      store.commit('SET_NOTIFICATION', {
        message: i18n.t(message),
        type: type,
        timeout: timeout
      })
    },

    $_section_title(data) {
      store.commit('SET_SECTION_TITLE', data)
    },

    $_format_number(number) {
      if (!number) return 0

      number = Number(number)
      if (isNaN(number)) return number

      return number.toLocaleString()
    },

    $_format_date_for_picker(date) {
      return Vue.moment(date).format('YYYY-MM-DD')
    },

    $_format_date(date) {
      return Vue.moment(date).format('DD/MM/YYYY')
    },

    $_format_date_time(date) {
      return Vue.moment(date).format('DD/MM/YYYY hh:mm a')
    },

    $_can(permission) {
      if (typeof this.authUserPermissions === 'string') {
        if (this.authUserPermissions === 'superuser') {
          return true
        }
      } else {
        for (const index in this.authUserPermissions) {
          if (permission === this.authUserPermissions[index].codename) {
            return true
          }
        }
      }

      return false
    },

    $_cans(permissions) {
      if (typeof this.authUserPermissions === 'string') {
        if (this.authUserPermissions === 'superuser') {
          return true
        }
      } else {
        for (const i in permissions) {
          for (const index in this.authUserPermissions) {
            if (permissions[i] === this.authUserPermissions[index].codename) {
              return true
            }
          }
        }
      }

      return false
    },

    $_confirm(propObject = { type: 'delete' }) {
      if (propObject.type == 'delete') {
        propObject = {
          title: propObject.title ?? 'Warning',
          titleColor: propObject.titleColor ?? 'error--text',
          message: propObject.message ?? 'This action delete the data permanently.',
          confirmBtnText: propObject.confirmBtnText ?? 'Delete',
          confirmBtnIcon: propObject.confirmBtnIcon ?? 'mdil-delete',
          confirmBtnColor: propObject.confirmBtnColor ?? 'error',
          cancelBtnText: propObject.cancelBtnText ?? 'Cancel',
        }
      } else if (propObject.type == 'approve') {
        propObject = {
          title: propObject.title ?? 'Are you sure ?',
          titleColor: propObject.titleColor ?? '',
          message: propObject.message ?? '',
          confirmBtnText: propObject.confirmBtnText ?? 'Yes',
          confirmBtnIcon: propObject.confirmBtnIcon ?? 'mdil-check',
          confirmBtnColor: propObject.confirmBtnColor ?? 'primary',
          cancelBtnText: propObject.cancelBtnText ?? 'Cancel',
        }
      }
      return new Promise(resolve => {
        const Wrapper = Vue.extend(Confirmation)
        const dialog = new Wrapper({
          propsData: {
            propObject,
          },
          router,
          store,
          vuetify,
          i18n,
        }).$mount()

        dialog.$on('close', value => {
          dialog.$destroy()
          dialog.$el.remove()
          resolve(value)
        })
      })
    },

    $_print(id = 'print-area') {
      let theme = this.$vuetify.theme.isDark
      this.$vuetify.theme.isDark = false

      setTimeout(() => {
        const prtHtml = document.getElementById(id).innerHTML
        // Get all stylesheets HTML
        let stylesHtml = ''
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }

        let vuetifyClasses = [
          ...document.querySelector('#app').classList,
          ...document.querySelector('.v-data-table').classList,
          ...document.querySelector('.v-application').classList,
        ]

        // let invoiceInfo = document.querySelector('#invoice-info')
        // invoiceInfo.classList.remove('d-none')

        vuetifyClasses = vuetifyClasses.toString().replaceAll(',', ' ').replace('v-data-table--fixed-header', '')

        const WinPrint = window.open('h', '', 'left=300px,top=0px,width=1000,height=1000,toolbar=0,scrollbars=0,status=0')

        WinPrint.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}

              <style>
                .v-data-table__wrapper {
                  width: 100%
                }

                #invoice-info {
                  display: table-cell !important
                }
              </style>
            </head>
            <body class="${vuetifyClasses}">
              ${prtHtml}
            </body>
          </html>
        `)

        this.$vuetify.theme.isDark = theme
        WinPrint.document.close()

        setTimeout(() => {
          WinPrint.focus()
          WinPrint.print()
          WinPrint.close()
        }, 500)
      }, 100)
    }
  },

  computed: {
    ...mapGetters([
      'authUserData',
      'authUserPermissions'
    ]),
  }
}