<template>
  <div>
    <v-autocomplete
      v-if="showProductSearch"
      id="app-item-search"
      v-model="selectedItem"
      :class="classes"
      :label="$t('Search item...')"
      prepend-inner-icon="mdil-magnify"
      :loading="loading"
      :items="itemsList"
      :search-input.sync="search"
      :readonly="readonly"
      return-object
      cache-items
      auto-select-first
      clearable
      outlined
      dense
      hide-no-data
      hide-details
      single-line
      @keypress.enter.prevent="searchForItems(search)"
      @change="handleChange(selectedItem)"
    >
      <template
        v-if="addItem"
        #append-outer
      >
        <v-btn
          color="primary"
          small
          depressed
          class="mt-n1 ms-n2"
          @click="addNewItem()"
        >
          <v-icon>
            mdil-plus
          </v-icon>
        </v-btn>
      </template>
    </v-autocomplete>

    <add-item
      :add-edit-dialog="itemDialog"
      :form-data="itemData"
      :edit-mode="false"
      @close="itemDialog = false"
      @reset-table-data="itemAdded($event)"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddItem from '../Item/AddEdit.vue'

export default {
  components: {
    AddItem
  },

  props: {
    exist: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    addItem: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    branchId: { type: Number, default: null },
    inventoryId: { type: Number, default: null },
    classes: { type: String, default: '' },
    endpoint: { type: String, default: 'item/search/' },
  },

  data() {
    return {
      showProductSearch: true,
      search: null,
      selectedItem: null,
      loading: false,
      itemsList: [],
      searchTimeout: null,
      itemData: {},
      itemDialog: false,
    }
  },

  watch: {
    branchId(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        this.searchReset()
        this.clearItemsList()
      }
    },

    // search(val) {
    //   clearTimeout(this.searchTimeout)
    //   this.searchTimeout = setTimeout(() => {
    //     this.searchForItems(val)
    //   }, 750)
    // },
  },

  methods: {
    searchForItems(keyword) {
      if (!keyword) return

      let payload = {
        keyword: keyword,
        exist: this.exist,
      }

      if (this.branchId) {
        payload.branch_id = this.branchId
      }

      if (this.inventoryId) {
        payload.inventory_id = this.inventoryId
      }

      axios.post(this.endpoint, payload).then(res => {
        if (res.data.length == 1) {
          this.$emit('selected', res.data[0])
          this.searchReset()
        }
        this.itemsList = res.data
      }).catch(() => {
        this.searchReset()
      })
    },

    searchReset() {
      this.selectedItem = ''
      this.showProductSearch = false
      this.$nextTick(() => {
        this.showProductSearch = true
        setTimeout(() => {
          document.getElementById('app-item-search').focus()
        }, 300)
      })
    },

    handleChange(item) {
      this.$emit('selected', item)
      this.searchReset()
    },

    clearItemsList() {
      this.itemsList = []
    },

    addNewItem() {
      this.itemData = {}
      this.itemDialog = true
    },

    itemAdded(item) {
      let obj = {
        id: item.id,
        text: `[${item.code}] ${item.name}`,
        deleted_at: null,
        name: item.name,
        code: item.code,
        is_disabled: false,
        min_quantity_alert: null,
        purchase_price: item.purchase_price,
        sale_price: item.sale_price,
        user: 1,
        updated_by: 1,
        branch: 1
      }

      this.handleChange(obj)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>